<template>
  <span class="create-school-years-button" v-if="canAdd">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-title="'Créer ' + editYear"
      :ok-disabled="saveInProgress"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-calendar"></i> Créer {{ editYear }}</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="season-group"
            label="Saison"
            label-for="season"
          >
          <b-form-input
            v-model="editYear"
            id="season"
            disabled
            :style="getColorStyle()"
          ></b-form-input>
        </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="start-date-group"
            label="Date de début"
            label-for="startDate"
            description="Si vide, dates par défaut"
          >
            <b-form-input
              id="startDate"
              name="startDate"
              v-model="startDate"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="end-date-group"
            label="Date de fin"
            label-for="endDate"
          >
            <b-form-input
              id="endDate"
              name="endDate"
              v-model="endDate"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <color-select
            :background="selectedBackgroundColor"
            :color="selectedTextColor"
            @selected="getSelectedColor($event)"
          ></color-select>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import ColorSelect from '@/components/Controls/ColorSelect'
import { makeSchoolYear } from '@/types/schools'

export default {
  name: 'CreateSchoolYearsModal',
  components: { ColorSelect, },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    theYear: Number,
    civil: Boolean,
    initialStartDate: String,
  },
  data() {
    return {
      errorText: '',
      name: '',
      startDate: '',
      endDate: '',
      editYear: '',
      selectedBackgroundColor: '',
      selectedTextColor: '',
      saveInProgress: false,
    }
  },
  created() {
    this.init()
  },
  watch: {
    theYear: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    init() {
      if (this.civil) {
        this.editYear = '' + this.theYear
        this.startDate = this.initialStartDate
        this.endDate = '' + this.theYear + '-12-31'
      } else {
        this.editYear = '' + this.theYear + '/' + (this.theYear + 1)
        this.startDate = this.initialStartDate
        this.endDate = '' + (this.theYear + 1) + '-08-31'
      }
    },
    getColorStyle() {
      return {
        background: this.selectedBackgroundColor,
        color: this.selectedTextColor,
      }
    },
    async onSave() {
      if (!this.saveInProgress) {
        this.saveInProgress = true
        let url = '/api/school-years/'
        let backendApi = new BackendApi('post', url)
        try {
          const data = {
            'start_year': this.theYear,
            'start_date': this.startDate || null,
            'end_date': this.endDate || null,
            'background_color': this.selectedBackgroundColor,
            'text_color': this.selectedTextColor,
          }
          const updated = makeSchoolYear(data)
          await backendApi.callApi(data)
          await this.addSuccess('L\'année à été créée')
          this.$bvModal.hide(this.modalId)
          this.$emit('done', updated)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.saveInProgress = false
      }
    },
    getSelectedColor(event) {
      this.selectedBackgroundColor = event.color.background
      this.selectedTextColor = event.color.text
    },
  },
  computed: {
    canAdd() {
      return this.hasPerm('schools.add_schoolyear')
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <span class="edit-school-years-button" v-if="canChange">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-title="'Modifier ' + editYear"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-calendar"></i> Modifier {{ editYear }}</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="season-group"
            label="Saison"
            label-for="season"
          >
          <b-form-input
            v-model="editYear"
            id="season"
            disabled
            :style="getColorStyle()"
          ></b-form-input>
        </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="start-date-group"
            label="Date de début"
            label-for="startDate"
            description="Si vide, dates par défaut"
          >
            <b-form-input
              id="startDate"
              name="startDate"
              v-model="startDate"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="end-date-group"
            label="Date de fin"
            label-for="endDate"
          >
            <b-form-input
              id="endDate"
              name="endDate"
              v-model="endDate"
              type="date"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <color-select
            :background="selectedBackgroundColor"
            :color="selectedTextColor"
            @selected="getSelectedColor($event)"
          ></color-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="closed-group"
            label-for="closed"
            :disabled="isActive"
            description="Les avoirs ne sont plus utilisables. Les factures ne sont plus accessibles"
          >
          <b-form-checkbox
            v-model="closed"
            id="closed"
            :disabled="isActive"
          >Clôturé</b-form-checkbox>
        </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="hasCertificates">
        <b-col>
          <b-form-group
            id="isCertificateActive-group"
            label-for="isCertificateActive"
            description="Si décoché, les justificatifs ne peuvent plus être saisis par les familles"
          >
          <b-form-checkbox
            v-model="isCertificateActive"
            id="isCertificateActive"
          >
            Saisie des justificatifs par les familles
          </b-form-checkbox>
        </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="active-group"
            label-for="active"
            description="L'année est accessible en historique"
          >
            <b-form-checkbox
              v-model="active"
              id="active"
              :disabled="isActive"
            >
              Active
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="showHistoryOnPortal-group"
            label-for="showHistoryOnPortal"
            description="L'année est accessible en historique depuis le portail familles"
          >
            <b-form-checkbox
              v-model="showHistoryOnPortal"
              id="showHistoryOnPortal"
              :disabled="isCurrent"
            >
              Historique portail famille
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="archived-group"
            label-for="archived"
            description="L'année est conservée pour statistique seulement. Les familles peuvent être anonymisés"
          >
            <b-form-checkbox
              v-model="archived"
              id="archived"
              :disabled="!canArchive"
            >
              Archivée
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import ColorSelect from '@/components/Controls/ColorSelect'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi } from '@/utils/http'
import { daysAgo } from '@/utils/dates'

export default {
  name: 'EditSchoolYearsModal',
  components: { ColorSelect, },
  mixins: [BackendMixin],
  props: {
    year: Object,
    modalId: String,
  },
  data() {
    return {
      errorText: '',
      name: '',
      startDate: '',
      endDate: '',
      editYear: '',
      selectedBackgroundColor: '',
      selectedTextColor: '',
      closed: false,
      active: true,
      archived: false,
      isCertificateActive: true,
      showHistoryOnPortal: true,
    }
  },
  created() {
    this.init()
  },
  watch: {
    year: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    init() {
      if (this.year) {
        this.editYear = '' + this.year.name
        this.startDate = this.year.startDate
        this.endDate = this.year.endDate
        this.selectedBackgroundColor = this.year.backgroundColor
        this.selectedTextColor = this.year.textColor
        this.active = this.year.isActive
        this.closed = this.year.isClosedForPayments
        this.isCertificateActive = this.year.isCertificateActive
        this.showHistoryOnPortal = this.year.showHistoryOnPortal
        this.archived = false
      }
    },
    showModal() {
      this.$bvModal.show(this.modalId)
    },
    canChange() {
      return this.hasPerm('schools.change_schoolyear')
    },
    getColorStyle() {
      return {
        background: this.selectedBackgroundColor,
        color: this.selectedTextColor,
      }
    },
    async onSave() {
      const url = '/api/school-years/' + this.year.id + '/'
      const backendApi = new BackendApi('patch', url)
      try {
        const data = {
          'start_year': this.year.startYear,
          'start_date': this.startDate || null,
          'end_date': this.endDate || null,
          'background_color': this.selectedBackgroundColor,
          'text_color': this.selectedTextColor,
          'is_certificate_active': this.isCertificateActive,
          'show_history_on_portal': this.showHistoryOnPortal,
        }
        if (!this.isActive) {
          data['is_closed_for_payments'] = this.closed
          data['is_active'] = this.active
        }
        if (this.canArchive) {
          data['is_archived'] = this.archived
        }
        const updated = makeSchoolYear(data)
        await backendApi.callApi(data)
        if (updated.isArchived) {
          await this.addWarning('L\'année à été archivée')
        } else {
          await this.addSuccess('L\'année à été modifiée')
        }
        this.$bvModal.hide(this.modalId)
        this.$emit('done', updated)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    getSelectedColor(event) {
      this.selectedBackgroundColor = event.color.background
      this.selectedTextColor = event.color.text
    },
  },
  computed: {
    isActive() {
      const newYear = moment().toDate().getFullYear()
      return this.year.startYear >= (newYear - 1)
    },
    isCurrent() {
      const days = daysAgo(this.year.startDate)
      return days < 365
    },
    canArchive() {
      return this.year.startYear < moment().year() - 3
    },
    hasCertificates() {
      return store.getters.config.hasCertificates
    },
  },
}
</script>

<style scoped>

</style>

<template>
<div class="school-years">
  <page-header title="Saisons" icon="fa fa-calendar"></page-header>
  <div v-if="schoolYears.length">
    <div>
      <b-row>
        <b-col>
          <h3 v-if="selectedSchoolYear">Année {{ selectedSchoolYear.name }}</h3>
          <h3 v-else>Veuillez sélectionner une année</h3>
        </b-col>
      </b-row>
      <div class="header-bar2">
        <b-row>
          <b-col>
            <span v-if="canAddSeason && showCreateNewSeason">
              <a href @click.prevent="showNewSeasonModal" class="btn btn-primary">
                <i class="fa fa-calendar"></i> Créer {{ newSeasonName }}
              </a>
            </span>
            <span v-for="schoolYear in schoolYears" :key="schoolYear.id">
              <a class="btn btn-secondary" href :class="isActive(schoolYear) ? 'active' : ''"
                 @click.prevent="changeSelectedYear(schoolYear)"
              >
                {{ schoolYear.name }}
              </a>
            </span>
          </b-col>
          <b-col cols="2" class="text-right">
            <b-checkbox name="showAll" v-model="showAll">Toutes</b-checkbox>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedSchoolYear" :style="schoolYearStyle" class="header-bar2">
        <b-row>
          <b-col cols="3">
            <div><b>{{ selectedSchoolYear.name }}</b></div>
            <div class="small-text">
              Du {{ selectedSchoolYear.startDate | dateToString('ddd D MMM YYYY') }}
              au {{ selectedSchoolYear.endDate | dateToString('ddd D MMM YYYY') }}
            </div>
          </b-col>
          <b-col>
            <span v-if="selectedSchoolYear">
              <a
                v-if="activityMenus.length === 0 && canViewActivities"
                href
                @click.prevent="loadActivities(null)"
                class="btn btn-secondary"
                :class="isActivityActive(null) ? 'active' : ''"
              >
                Activités
              </a>
              <a
                v-for="menu of activityMenus"
                :key="menu.id"
                @click.prevent="loadActivities(menu)"
                class="btn btn-secondary"
                :class="isActivityActive(menu) ? 'active' : ''"
              >
                {{ menu.label }}
              </a>
            </span>
            <a
              v-if="selectedSchoolYear && canViewYouthHomes" href
              @click.prevent="loadYouthHome()"
              class="btn btn-secondary"
              :class="isYouth ? 'active' : ''"
            >
              {{ youthHomeLabel }}
            </a>
            <a
              v-if="selectedSchoolYear && canViewBankHolidays" href
              @click.prevent="loadBankHolidays()"
              class="btn btn-secondary"
              :class="isBankHolidays ? 'active' : ''"
            >
              Jours fériés
            </a>
          </b-col>
          <b-col cols="2">
            <span
              v-if="selectedSchoolYear.isClosedForPayments"
              class="badge badge-warning"
              title="Les avoirs ne sont plus utilisables. Les factures ne sont plus accessibles"
              v-b-tooltip
            >
              Clôturé
            </span>
            &nbsp;
            <span v-if="selectedSchoolYear.isActive" class="badge badge-dark">
              Active
            </span>
            <span v-else class="badge badge-warning">Historique</span>
          </b-col>
          <b-col cols="2" class="text-right">
            <a @click.prevent="showModalEditYear()" class="btn btn-primary">
              <i class="fa fa-calendar"></i> Modifier {{ selectedSchoolYear.name }}
            </a>
          </b-col>
        </b-row>
      </div>
      <div v-if="!isLoading(loadingName)">
        <div class="activities-box" v-if="selectedSchoolYear && isBankHolidays && canLoadBankHolidays">
          <div v-if="holidaysCreated">
            L'opération peut prendre quelques minutes
            <a class="btn btn-secondary" href @click.prevent="loadBankHolidays()">
              Rafraîchir
            </a>
            &nbsp;
            <a class="btn btn-secondary" href @click.prevent="holidaysCreated = false">
              Réessayer
            </a>
          </div>
          <div v-else>
            <a class="btn btn-danger" href @click.prevent="createBankHolidays">
              <i class="fa fa-calendar-plus"></i> MAJ des jours fériés et vacances
              {{ selectedSchoolYear.name }}
            </a>
          </div>
        </div>
      </div>
      <b-row>
        <b-col>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div v-if="!isLoading(loadingName)">
            <div class="activities-box" v-if="selectedSchoolYear && selectedActivities.length && isActivity">
              <div>
                <h3>
                  <counter-label :counter="selectedActivities.length" label="Activité"></counter-label>
                  à créer pour {{ selectedSchoolYear.name }}
                </h3>
              </div>
              <div v-for="activity in selectedActivities" :key="activity.id">
                <i class="fa fa-chess-knight" v-if="activeMenu === null"></i>
                <i :class="activeMenu.icon" v-else></i>
                {{ activity.name }}
              </div>
              <div class="button-holder">
                <a
                  href @click.prevent="onSave"
                  class="btn btn-primary"
                  :class="creating ? 'disabled' : ''"
                >
                  Cloner les activités pour {{ selectedSchoolYear.name }}
                </a>
              </div>
            </div>
            <div class="activities-box" v-if="selectedSchoolYear && isActivity">
              <h3>
                <counter-label :counter="activities.length" label="Activité"></counter-label>
                en {{ selectedSchoolYear.name }}
              </h3>
              <div v-for="activity in activities" :key="activity.id">
                <router-link :to="getLinkToActivity(activity)">
                  <i class="fa fa-chess-knight" v-if="activeMenu === null"></i>
                  <i :class="activeMenu.icon" v-else></i>
                  {{ activity.name }}
                </router-link>
              </div>
            </div>
            <div class="activities-box" v-if="selectedSchoolYear && selectedYouths.length && isYouth">
              <div>
                <h3>
                  <counter-label :counter="selectedYouths.length" label="Accueil"></counter-label>
                  à créer pour {{ selectedSchoolYear.name }}
                </h3>
              </div>
              <div v-for="youth in selectedYouths" :key="youth.id">
                <i :class="youthHomeIcon"></i> {{ youth.name }}
              </div>
              <div class="button-holder">
                <a
                  href @click.prevent="onSave"
                  class="btn btn-primary"
                  :class="creating ? 'disabled' : ''"
                >
                  Cloner les accueils pour {{ selectedSchoolYear.name }}
                </a>
              </div>
            </div>
            <div class="activities-box" v-if="selectedSchoolYear && isBankHolidays">
              <h3>
                <counter-label :counter="schoolBankHolidays.length" label="vacance"></counter-label>
                en {{ selectedSchoolYear.name }}
              </h3>
              <table class="table table-striped">
                <tr v-for="holiday in schoolBankHolidays" :key="holiday.id">
                  <td>
                    <i class="fa fa-calendar-days"></i> {{ holiday.name }}
                  </td>
                  <td>
                    <span v-if="holiday.endDate">
                     du
                    </span>
                    <span v-else>le</span>
                    {{ holiday.date | dateToString('dddd D MMMM YYYY') }}
                    <span v-if="holiday.endDate">
                     au {{ holiday.endDate | dateToString('dddd D MMMM YYYY') }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div class="activities-box" v-if="selectedSchoolYear && isYouth">
              <h3>
                <counter-label :counter="youths.length" label="Accueil"></counter-label>
                en {{ selectedSchoolYear.name }}
              </h3>
              <div v-for="youth in youths" :key="youth.id">
                <router-link :to="getLinkToYouth(youth)">
                  <i :class="youthHomeIcon"></i> {{ youth.name }}
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
        <b-col v-if="!isLoading(loadingName)">
          <div v-if="prevYear && prevActivities.length && isActivity">
            <h3>Activités {{ prevYear.name }}</h3>
            <check-box-select
              id="activities"
              :choices="prevActivities"
              @changed="activitiesChanged($event)"
            >
            </check-box-select>
          </div>
          <div v-if="prevYear && prevYouths.length && isYouth">
            <h3>Accueils {{ prevYear.name }}</h3>
            <check-box-select
              id="youths"
              :choices="prevYouths"
              @changed="youthsChanged($event)"
            >
            </check-box-select>
          </div>
          <div class="activities-box" v-if="selectedSchoolYear && isBankHolidays">
            <h3>
              <counter-label :counter="otherBankHolidays.length" label="férié"></counter-label>
              en {{ selectedSchoolYear.name }}
            </h3>
            <table class="table table-striped">
              <tr v-for="holiday in otherBankHolidays" :key="holiday.id">
                <td>
                  <i class="fa fa-calendar-day"></i> {{ holiday.name }}
                </td>
                <td>
                  <span v-if="holiday.endDate">
                   du
                  </span>
                  <span v-else>le</span>
                  {{ holiday.date | dateToString('dddd D MMMM YYYY') }}
                  <span v-if="holiday.endDate">
                   au {{ holiday.endDate | dateToString('dddd D MMMM YYYY') }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
  <create-school-years-modal
    v-if="showCreateNewSeason"
    :the-year="newSeason"
    modal-id="create-school-years-modal"
    :initial-start-date="nextYearStartDate"
    :civil="isCivilYear"
    @done="refreshSchoolYear"
  >
  </create-school-years-modal>
  <edit-school-years-modal
    v-if="selectedSchoolYear"
    :year="selectedSchoolYear"
    modal-id="edit-school-years-modal"
    @done="refreshSchoolYear"
  >
  </edit-school-years-modal>
</div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import EditSchoolYearsModal from '@/components/School/EditSchoolYearsModal'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity } from '@/types/activities'
import { makeBankHoliday, makeSchoolYear } from '@/types/schools'
import { BackendApi } from '@/utils/http'
import { makeYouthHome } from '@/types/youth'
import store from '@/store'
import router from '@/router'
import CreateSchoolYearsModal from '@/components/School/CreateSchoolYearsModal.vue'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'SchoolYearsList',
  components: {
    CreateSchoolYearsModal,
    CounterLabel,
    LoadingGif,
    CheckBoxSelect,
    EditSchoolYearsModal,
    PageHeader,
  },
  mixins: [BackendMixin],
  data() {
    return {
      allSchoolYears: [],
      activeSchoolYears: [],
      currentYear: null,
      youths: [],
      selectedSchoolYear: null,
      selectedActivities: [],
      selectedYouths: [],
      activities: [],
      prevActivities: [],
      prevYouths: [],
      loadingName: 'school-years-list',
      creating: false,
      isActivity: false,
      isYouth: false,
      newSeason: 0,
      bankHolidays: [],
      isBankHolidays: false,
      holidaysCreated: false,
      showAll: false,
      activeMenu: null,
    }
  },
  computed: {
    schoolYears() {
      if (!this.showAll) {
        return this.activeSchoolYears
      } else {
        return this.allSchoolYears
      }
    },
    activityMenus() {
      return store.getters.config.activityMenus
    },
    isCivilYear() {
      const lastSeason = this.lastSeason
      if (lastSeason) {
        return lastSeason.isCivilYear
      }
      return false
    },
    nextYearStartDate() {
      const lastSeason = this.lastSeason
      if (lastSeason) {
        return moment(lastSeason.endDate).add(1, 'days').format('YYYY-MM-DD')
      }
      return ''
    },
    newSeasonName() {
      if (this.isCivilYear) {
        return '' + this.newSeason
      } else {
        return '' + this.newSeason + '/' + (this.newSeason + 1)
      }
    },
    schoolYearStyle() {
      return {
        backgroundColor: this.selectedSchoolYear.backgroundColor,
        color: this.selectedSchoolYear.textColor,
      }
    },
    schoolBankHolidays() {
      return this.bankHolidays.filter(elt => elt.isSchool)
    },
    otherBankHolidays() {
      return this.bankHolidays.filter(elt => !elt.isSchool)
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    prevYear() {
      if (this.selectedSchoolYear) {
        for (let schoolYear of this.allSchoolYears) {
          if (schoolYear.startYear === (this.selectedSchoolYear.startYear - 1)) {
            return schoolYear
          }
        }
      }
      return null
    },
    canAddSeason() {
      return this.hasPerm('schools.add_schoolyear')
    },
    canViewYouthHomes() {
      return this.hasPerm('youth.add_youthhomeactivity')
    },
    canViewActivities() {
      return this.hasPerm('activities.add_coreactivity')
    },
    canViewBankHolidays() {
      return this.hasPerm('schools.view_bankholiday')
    },
    canLoadBankHolidays() {
      return this.hasPerm('schools.add_bankholiday')
    },
    lastSeason() {
      const schoolYears = [].concat(this.allSchoolYears)
      const lastSeasons = schoolYears.sort(
        (season1, season2) => -compareNumbers(season1.startYear, season2.startYear)
      )
      if (lastSeasons.length) {
        return lastSeasons[0]
      }
      return null
    },
    showCreateNewSeason() {
      if (this.lastSeason) {
        const diffMonths = -moment().diff(this.lastSeason.endDate, 'days')
        return diffMonths < 250
      }
      return false
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadSchoolYears()
    },
    isActivityActive(menu) {
      return this.isActivity && (menu === this.activeMenu)
    },
    isActive(schoolYear) {
      if (this.selectedSchoolYear) {
        return schoolYear.id === this.selectedSchoolYear.id
      }
      return false
    },
    getLinkToActivity(activity) {
      return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
    },
    getLinkToYouth(youth) {
      return { name: 'youth-homes-detail', params: { activityId: '' + youth.activity, }, }
    },
    async loadSchoolYears() {
      this.selectedSchoolYear = null
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allSchoolYears = resp.data.map(elt => makeSchoolYear(elt))
        this.activeSchoolYears = this.allSchoolYears.filter(elt => elt.isActive)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      const years = this.schoolYears.map(elt => elt.startYear)
      this.newSeason = Math.max(...years) + 1
    },
    async getActivities(schoolYear) {
      let url = '/api/activities/activities/?school_year=' + schoolYear.id
      if (this.activeMenu) {
        url += '&category=' + this.activeMenu.category
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        return resp.data.map(elt => makeActivity(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      return []
    },
    async getYouths(schoolYear) {
      let url = '/api/youth/youth-homes/?school_year=' + schoolYear.id
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        return resp.data.map(elt => makeYouthHome(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      return []
    },
    async loadActivities(menu) {
      if (this.selectedSchoolYear) {
        this.startLoading(this.loadingName)
        this.isYouth = false
        this.isBankHolidays = false
        this.prevActivities = []
        this.selectedActivities = []
        this.activities = []
        this.activeMenu = menu
        this.activities = await this.getActivities(this.selectedSchoolYear)
        this.isActivity = true
        if (this.isNewSeason()) {
          // Pour l'année en cours, permettre de cloner depuis l'année précédente
          const prevYear = this.prevYear
          if (prevYear) {
            this.prevActivities = await this.getActivities(prevYear)
          }
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadYouthHome() {
      if (this.selectedSchoolYear) {
        this.startLoading(this.loadingName)
        this.isActivity = false
        this.activeMenu = false
        this.isBankHolidays = false
        this.prevYouths = []
        this.selectedYouths = []
        this.youths = []
        this.youths = await this.getYouths(this.selectedSchoolYear)
        this.isYouth = true
        if (this.isNewSeason()) {
          // Pour l'année en cours, permettre de cloner depuis l'année précédente
          const prevYear = this.prevYear
          if (prevYear) {
            this.prevYouths = await this.getYouths(prevYear)
          }
        }
        this.endLoading(this.loadingName)
      }
    },
    async createActivities() {
      if (this.selectedSchoolYear && !this.creating) {
        this.creating = true
        try {
          const activities = this.selectedActivities.map(activity => activity.id)
          const data = {
            'activities': activities,
            'school_year': this.selectedSchoolYear.id,
          }
          let backendApi = new BackendApi('post', '/api/activities/clone-activities/')
          await backendApi.callApi(data)
          await this.addSuccess('Les activités ont été ajoutées')
          await this.loadActivities(this.activeMenu)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.creating = false
      }
    },
    async createYouths() {
      if (this.selectedSchoolYear && !this.creating) {
        this.creating = true
        try {
          const youths = this.selectedYouths.map(youth => youth.id)
          const data = {
            'activities': youths,
            'school_year': this.selectedSchoolYear.id,
          }
          let backendApi = new BackendApi('post', '/api/youth/clone-youths/')
          await backendApi.callApi(data)
          await this.addSuccess('Les accueils ont été ajoutées')
          await this.loadYouthHome()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.creating = false
      }
    },
    activitiesChanged(event) {
      this.selectedActivities = event.choices
    },
    youthsChanged(event) {
      this.selectedYouths = event.choices
    },
    isNewSeason() {
      if (this.selectedSchoolYear) {
        return this.selectedSchoolYear === this.lastSeason
      }
      return false
    },
    async changeSelectedYear(year) {
      this.selectedSchoolYear = year
      if (this.isYouth) {
        await this.loadYouthHome()
      }
      if (this.isActivity) {
        await this.loadActivities(this.activeMenu)
      }
      if (this.isBankHolidays) {
        await this.loadBankHolidays()
      }
    },
    onSave() {
      if (this.isActivity) {
        this.createActivities()
      }
      if (this.isYouth) {
        this.createYouths()
      }
    },
    async createBankHolidays() {
      if (this.selectedSchoolYear) {
        this.startLoading(this.loadingName)
        let url = '/api/bank-holidays/' + this.selectedSchoolYear.id + '/'
        let backendApi = new BackendApi('post', url)
        try {
          let resp = await backendApi.callApi()
          const that = this
          this.holidaysCreated = true
          setTimeout(
            function() {
              that.holidaysCreated = false
            },
            1000 * 60
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    showNewSeasonModal() {
      this.$bvModal.show('create-school-years-modal')
    },
    showModalEditYear() {
      this.$bvModal.show('edit-school-years-modal')
    },
    async loadBankHolidays() {
      if (this.selectedSchoolYear) {
        this.startLoading(this.loadingName)
        this.isYouth = false
        this.isActivity = false
        let url = '/api/bank-holidays/' + this.selectedSchoolYear.id + '/?include_school=1'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.bankHolidays = resp.data.map(elt => makeBankHoliday(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.isBankHolidays = true
        this.endLoading(this.loadingName)
      }
    },
    async refreshSchoolYear(event) {
      if (event.isArchived) {
        await router.push({ name: 'archive-families', })
      } else {
        const schoolYearId = this.selectedSchoolYear ? this.selectedSchoolYear.id : 0
        await this.loadSchoolYears()
        const matching = this.schoolYears.filter(elt => elt.id === schoolYearId)
        if (matching.length) {
          this.selectedSchoolYear = matching[0]
        } else if (this.schoolYears.length) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      }
    },
  },
}
</script>

<style scoped>
a.btn-secondary.active {
  background: #444 !important;
  color: #fff !important;
}
.activities-box {
  padding: 20px;
  margin-bottom: 20px;
  border: solid 1px #e0e0e0;
  border-radius: 4px;
}
.button-holder {
  margin: 10px 0;
  text-align: left;
}
.header-bar2 {
  margin-bottom: 12px;
  padding: 5px;
  background: #eee;
}
.header-bar2 a {
  display: inline-block;
  margin-right: 2px;
}
</style>
